<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : '处理' }}求助与咨询</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="标题">
				<el-input v-model.trim="ruleForm.title" disabled></el-input>
			</el-form-item>
			<el-form-item label="类型" class="w50 mr16">
				<el-input v-model.trim="ruleForm.resortConsultTypeDictionaryItem" disabled></el-input>
			</el-form-item>
			<el-form-item label="求助用户" class="w50 mr0">
				<el-input v-model.trim="ruleForm.attention" disabled></el-input>
			</el-form-item>
			<el-form-item label="求助时间">
				<el-input v-model.trim="ruleForm.createTime" disabled></el-input>
			</el-form-item>
			<el-form-item label="正文">
				<el-input v-model.trim="ruleForm.content" disabled type="textarea" resize="none" autosize></el-input>
			</el-form-item>
			<el-form-item label="图片">
				<div class="imgs">
					<img v-for="(item, index) in images" :key="index" :src="item.url" />
				</div>
			</el-form-item>
			<el-form-item label="审核" prop="examineTypeState">
				<el-select v-model="ruleForm.examineTypeState" :disabled="isShowDetail" placeholder="请选择" @change="changeSelect">
					<el-option label="通过" value="A"></el-option>
					<el-option label="不通过" value="P"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="处理方式" v-if="ruleForm.examineTypeState">
				<el-input v-model.trim="ruleForm.dealContent" :disabled="isShowDetail" type="textarea" resize="none" autosize></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			createdVisible: false,
			images: [],
			ruleForm: {
				examineTypeState: '',
				dealContent: '',
			},
			rules: {
				examineTypeState: [{ required: true, message: '请选择审核状态', trigger: 'blur' }],
			},
			saveLoading: false,
			link: '',
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.link = data.link;
			if (data.row) {
				this.getDetail(this.link);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取详情
		getDetail(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.setRuleForm(res.data.collection[0]);
					}
				})
				.catch((e) => {});
		},
		// 修改/查看
		setRuleForm(data) {
			this.ruleForm = {
				...data,
				resortConsultTypeDictionaryItem: data.resortConsultTypeDictionaryItem?.title || '',
				attention: data.creator?.title || '',
				createTime: data.createTime || '',
			};
			this.images =
				(data.attachmentDtoMap &&
					data.attachmentDtoMap['PHOTO'].map((item) => {
						return {
							title: item.title,
							url: item.link,
							id: item.id,
						};
					})) ||
				[];
		},
		onResetForm(formName) {
			this.images = [];
			this.createdVisible = false;
			this.$refs[formName].resetFields();
		},
		changeSelect(val) {
			this.ruleForm.dealContent = '';
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						state: 'T',
					};
					this.$http
						.put(this.link, params)
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success('操作成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.el-textarea__inner {
		min-height: 64px !important;
		line-height: 18px !important;
		padding: 5px 12px !important;
	}
}

/deep/ .el-button {
	margin-left: 16px;
}

/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
</style>